/*Agregado*/

/* Estilos generales agregados*/
.visible-xs{
  display: none;
  @media only screen and (max-width: 575px) {
    display: block;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
}
.hidden-xs{
  display: block;
  @media only screen and (max-width: 575px) {
    display: none;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    display: none;
  }
}
.no-display {
  display: none;
}
/* /Estilos generales agregados*/


/*Estilos adicionados*/
/* TopbarUser */ //ok
.img-user-profile {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  @media only screen and (max-width: 575px) {
    width: 45px;
    height: 45px;
  }
}
.isoImgWrapper {
  width: 71px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    margin-left: 5px;
  }
}
span.userText {
  margin-right: 25px;
  @media only screen and (max-width: 575px) {
    margin-right: 10px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    margin-right: 10px;
  }
}
.ant-popover-arrow {
  display: none !important;
}

.input-search-topbar
{
  display: flex;
  align-items: center;
  .input-search
  {
    display: flex;
    align-items: center;
    border-right: 1px solid $color-primary-17;
    margin-right: 20px;
    padding-right: 15px;
    .inputSearchText
    {
      border:none;
      background-color: $color-primary-0;
      border-bottom: 1px solid $color-primary-14;
      color:$color-primary-1;
      min-width: 350px;
      @media only screen and (max-width: 575px) {
        min-width: 200px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        min-width: 200px;
      }
      &:hover,&:active,&:focus, &:focus-visible
      {
        border:none;
        background-color: $color-primary-0;
        border-bottom: 1px solid $color-primary-14;
      }
      ::-webkit-input-placeholder {
        color: $color-primary-17;
      }
      ::-moz-placeholder {
        color: $color-primary-17;
      }
      :-ms-input-placeholder {
        color: $color-primary-17;
      }
      :-moz-placeholder {
        color: $color-primary-17;
      }
      &:focus,&:active{
          outline: none;
      }
    }
    .img-search-text
    {
      margin-left: 10px;
    }
  }
  .isoRight
  {
    .logo-topbar-mobile
    {
      display: none;
      @media only screen and (max-width: 575px) {
        display: inline-block;
      }
    }
  }
}
/* /TopbarUser */

/* inicio */
/* Cursos */ //ok
.background-cursos {
  /*background-image: url(${bg_programa});*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .layer-cursos
  {
    min-height: 360px;
    padding: 15px 43px 35px 38px;
    align-items: flex-end;
    display: flex;
    background-color: rgba(0, 0, 0, 0.55);
    @media only screen and (max-width: 575px) {
      padding: 15px 10px 20px 10px;
      min-height: inherit;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      padding: 25px 30px 25px 30px;
      min-height: inherit;
    }
  }
  h2 {
    margin-bottom: 7px;
  }
}

.background-apply-now {
  display: flex;
  align-items: center;
  padding: 28px 43px 29px 38px;
  p {
    margin-bottom: 0px;
    font-weight: 300;
    margin-right: 20px;
  }
  @media only screen and (max-width: 575px) {
    padding: 20px 15px 20px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 20px 15px 20px 15px;
  }  
}

.columna-article-gcl {
  margin-bottom: 35px;
  @media only screen and (max-width: 575px) {
    margin-bottom: 22px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 22px;
  }
}

.article-gcl {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 35px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  @media only screen and (max-width: 575px) {
    padding-bottom: 22px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 22px;
  }
  .images-article-gcl 
  {
    width: 210px;
    position: relative;
    @media only screen and (min-width: 1200px) and (max-width: 1399px) 
    {
      width: 160px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
      width: 160px;
    }
    @media only screen and (max-width: 575px) {
      width: 125px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 125px;
    }
    img {
      border-radius: 10px;
      &:first-child {
        position: relative;
        z-index: 100;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
      &:nth-child(2) {
        position: absolute;
        left: 50px;
        top: 0px;
        z-index: 50;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
          left: 35px;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1399px) 
        {
          left: 35px;
        }
        @media only screen and (max-width: 575px) {
          left: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          left: 20px;
        }
      }
      &:nth-child(3) {
        position: absolute;
        left: 100px;
        top: 0px;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        @media only screen and (min-width: 1200px) and (max-width: 1399px) 
        {
          left: 65px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
          left: 65px;
        }
        @media only screen and (max-width: 575px) {
          left: 40px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          left: 40px;
        }
      }
    }
    .img-photo{
      width: 80px;
      height: auto;
      @media only screen and (max-width: 575px) {
        width: 55px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 55px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        width: 55px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) 
      {
        width: 55px;
      }
    }
  }
  .text-article-gcl {
    flex: 5;
    padding-left: 3%;
    margin-right: 10px;
    h3 {
      margin-bottom: 4px;
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      @media only screen and (max-width: 575px) {
        font-size: 17px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 17px;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1399px) 
      {
        font-size: 19px;
        word-break: break-word;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        font-size: 19px;
        word-break: break-word;
      }
    }
    p {
      font-weight: 300;
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 17px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) 
    { 
      padding-left: 2%;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
      padding-left: 2%;
    }
    @media only screen and (max-width: 575px) {
      padding-left: 2%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      padding-left: 2%;
    }
  }
  .button-article-gcl {
    text-align: right;
    .arrow_mob{
      width: 11px;
      height: auto;
    }
    @media only screen and (max-width: 575px) {
      min-width: 20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      min-width: 20px;
    }
  }
}

.content-cursos {
  padding: 35px 43px 35px 38px;
  @media only screen and (max-width: 575px) {
    padding: 22px 15px 22px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 22px 15px 22px 15px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
    width: 100%;
    justify-content: space-evenly;
  }
  .ant-tabs {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media only screen and (max-width: 575px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 14.5px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 15px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 15.5px;
    }
    p {
      margin-bottom: 16px;
      @media only screen and (max-width: 575px) {
        font-size: 14px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 14.5px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 15px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 15.5px;
      }
    }
    ul 
    {
      margin-bottom: 15px;
    }
    h3 
    {
      @media only screen and (max-width: 575px) {
        margin-bottom: 13px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
    img.img-icon-tab
    {
      @media only screen and (max-width: 575px) {
        display: none !important;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        display: none !important;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 35px;
    @media only screen and (max-width: 575px) {
      margin-bottom: 18px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 18px;
    }
  }
  .ant-tabs-nav-container {
    font-size: 26px;
    line-height: 31px;
    @media only screen and (max-width: 575px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 25px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 600;
    padding: 20px 60px;
    font-size: 18px;
    margin-left: 0px;
    @media only screen and (max-width: 575px) {
      padding: 8px 5px;
      font-size: 14px;
      font-weight: 500;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      padding: 9px 7px;
      font-size: 14.5px;
      font-weight: 500;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      padding: 18px 14px;
      font-size: 19px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      padding: 19px 15px;
      font-size: 18px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) 
    {
      padding: 20px 35px;  
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    position: relative;
    &::after {
      content: " ";
      display: block;
      width: 60%;
      height: 7px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      bottom: -3px;
      left: 50%;
      z-index: 100;
      transform: translateX(-50%);
    }
  }
  .ant-tabs-bar {
    margin-bottom: 40px;
    @media only screen and (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .textTab{
    display: flex;
    align-items: center;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations
  {
    display: none;
  }
}

.section-info-profile-firts:not(.ant-col){
  & > .section-info-profile
  {
    &:first-child{
      padding-top: 0px;
    }
  }
}

.content-cursos.content-cursos-nopadding{
  padding: 35px 0px 35px 0px;
}

.content-cursos.content-tabs-evelyn
{
  padding: 35px 0px 35px 0px;
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list
  {
    justify-content: space-evenly;    
  }
  .ant-tabs-top > .ant-tabs-nav{
    margin-bottom: 0px;
  }
}

.page-white
{
  font-weight: 300;
  color:$color-primary-2;
  line-height: 21px;
  @media only screen and (max-width: 575px) {
    line-height: 18px;  
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    line-height: 18px;  
  }
  h2{
    color:$color-primary-2;
    margin-bottom: 5px;
  }
  h3{
    color:$color-primary-2;
    margin-bottom: 8px;
  }
  h4{
    color:$color-primary-2;
  }
  p{
    color:$color-primary-2;
  }
  .h3-date{
    color:$color-primary-10;
    font-weight: 300;
  } 
  img{
    max-width: 100%;
    height: auto;
  }
}
/* /inicio */

/* Institutions */
.bg-add-new-institutions
{
  background-color: $color-primary-4 ;
  padding: 28px 43px 28px 38px;
  display: flex;
  align-items: center;
  h3{
    margin-bottom: 0px;
    margin-right: 25px;
    font-weight: 300;
    @media only screen and (max-width: 575px) 
    {
      transform: translateY(5px);
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
      transform: translateY(5px);
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 18px 15px 18px 15px;
    justify-content: space-between;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 18px 15px 18px 15px;
    justify-content: space-between;
  }
}

.bg-content-tabs{
  padding: 40px 43px 40px 38px;
  @media only screen and (max-width: 575px) {
    padding: 20px 15px 20px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 20px 15px 20px 15px;
  }
}

.bg-content-padding{
  padding: 0px 43px 0px 38px;
  @media only screen and (max-width: 575px) {
    padding: 0px 15px 0px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 0px 15px 0px 15px;
  }
}
/* /Institutions */

/* Perfil */
.header-profile {
  display: flex;
  flex-wrap: nowrap;
  .h2-profile {
    margin-bottom: 8px;
    font-size: 28px;
    line-height: 30px;
    @media only screen and (max-width: 575px) {
      font-size: 22px;
      line-height: 25px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 23px;
      line-height: 26px;
    }
  }
  .p-profile {
    margin-bottom: 8px;
    @media only screen and (max-width: 575px) {
      line-height: 18px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      line-height: 18px;
    }
  }
  @media only screen and (max-width: 575px) {
    padding-top: 12px;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding-top: 12px;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 35px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 37px;
    margin-bottom: 32px;
  }
  .data-img-profile {
    flex: 3;
    margin-right: 25px;
    @media only screen and (max-width: 575px) {
      margin-right: 0px;
      width: 100%;
      flex: inherit;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin-right: 0px;
      width: 100%;
      flex: inherit;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 0px;
      width: 100%;
      flex: inherit;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-right: 1%;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      margin-right: 1%;
    }
    .image-profile {
      width: 150px;
      height: 150px;
      position: relative;
      @media only screen and (max-width: 575px) {
        width: 95px;
        height: 95px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        width: 97px;
        height: 97px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 120px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 125px;
        height: 125px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 125px;
        height: 125px;
      }
      img.img-profile {
        max-width: 100%;
        height: auto;
        border-radius: 15px;
        border: 3px solid $color-primary-1;
        display: inline-block;
        width: 150px;
        height: 150px;
        @media only screen and (max-width: 575px) {
          width: 93px;
          height: 93px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
          width: 95px;
          height: 95px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 118px;
          height: 118px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 123px;
          height: 123px;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 123px;
          height: 123px;
        }
      }
    }
  }

  .profile-contact {
    flex: 22;
    @media only screen and (max-width: 575px) {
      text-align: center;
      flex: inherit;
      width: 100%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      text-align: center;
      flex: inherit;
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      flex: inherit;
      text-align: center;
      width: 100%;
    }
    .profile-social-media 
    {
      padding: 0px 0px 0px 0px;
      margin: 0px;
      display: flex;
      font-size: 18px;
      margin-bottom: 15px;
      flex-wrap: wrap;
      @media only screen and (max-width: 575px) {
        font-size: 15px;
        justify-content: center;
        flex-wrap: wrap;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        font-size: 15px;
        justify-content: center;
        flex-wrap: wrap;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) 
      {
        font-size: 16px;
        justify-content: center;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        font-size: 16.5px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) 
      {
        font-size: 16px;
      }
      li 
      {
        background-image: inherit;
        padding: 0px;
        margin: 0px;
        margin-left: 15px;
        a {
          color: $color-primary-18;
          &:hover,
          &:active,
          &:focus {
            color: $color-primary-18;
          }
        }
        &::before {
          content: "\2022";
          display: inline-block;
          margin-right: 15px;
        }
        &:first-child {
          margin-left: 0px;
          &::before {
            content: none;
          }
        }
        .p-profile {
          font-size: 15px;
        }
      }
    }
    .profile--ul-social-media {
      padding: 0px;
      margin: 0px;
      display: flex;
      li {
        background-image: inherit;
        padding: 0px;
        margin: 0px;
        margin-left: 23px;
        &:first-child {
          margin-left: 0px;
        }
        .img-socialmedia{
          width: 25px;
          height: 25px;
          @media only screen and (max-width: 575px) 
          {
            width: 23px;
            height: 23px;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) 
          {
            width: 24px;
            height: 24px;
          }
        }
      }
      @media only screen and (max-width: 575px) 
      {
        justify-content: center;        
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        justify-content: center;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) 
      {
        justify-content: center;
      }
    }
  }

  .content-btn {
    flex: 6;
    text-align: right;
    a {
      font-size: 0.9em;
    }
    @media only screen and (max-width: 575px) {
      padding-top: 20px;
      text-align: center;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      padding-top: 20px;
      text-align: center;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      padding-top: 20px;
      text-align: center;
    }
  }
}
.header-profile .data-img-profile .image-profile.image-profile-award {
  text-align: center;
  height: 165px;
  @media only screen and (max-width: 767px) {
    height: 120px;
  }
}
.img-impact-award {
  width: 90px;
  height: auto;
  position: relative;
  bottom: 40px;
  @media only screen and (max-width: 767px) {
    width: 80px;
    bottom: 30px;
  }
}

.bg-padding-header {
  padding: 15px 30px 35px 30px;
  @media only screen and (max-width: 575px) {
    padding: 15px 15px 27px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) 
  {
    padding: 15px 15px 27px 15px;
  }
}

.bg-padding-header-bottom {
  padding-bottom: 0px;
  position:relative;
  .content-btn
  {
    position:absolute;
    top:20px;
    right:30px;
    @media only screen and (max-width: 575px) {
      right:20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
      right:20px;
    }
  }
}

.ul-links {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-primary-16;
  padding-bottom: 20px !important;
  li {
    background-image: inherit;
    padding: 0px;
    margin: 0px;
    a {
      color: $color-primary-4;
      &:hover,
      &:active,
      &:focus {
        color: $color-primary-4;
      }
      img 
      {
        &:first-child {
          margin-right: 10px;
          width: 24px;
          height: auto;
          @media only screen and (max-width: 575px) {
            width: 22px;
            height: auto;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 23px;
            height: auto;
          }
        }
        &:last-child {
          margin-left: 10px;
          width: 8px;
          height: auto;
          @media only screen and (max-width: 575px) {
            width: 7px;
            height: auto;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 7px;
            height: auto;
          }
        }
      }
    }
  }
}

.section-info-profile {
  border-bottom: 1px solid $color-primary-16;
  padding-bottom: 20px;
  padding-top: 18px;
  color: $color-primary-2;
  font-weight: 300;
  span.span-title-info 
  {
    color: $color-primary-2;
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    @media only screen and (max-width: 575px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 10px;
    color: $color-primary-2;
    line-height: 21px;
    @media only screen and (max-width: 575px) {
      line-height: 18px;  
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      line-height: 18px;  
    }
  }
  h2, h3, h4, h5, h6, div {
    color: $color-primary-2;
  }
  @media only screen and (max-width: 575px) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 14px;
    padding-top: 14px;
  }
}

.section-description {
  border-bottom: none;
  padding-bottom: 15px;
  padding-top: 0px;
  p {
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 18px;
  }
}
html body div.section-description ul {
  margin-bottom: 15px ;
}

.form-award {
  .ant-row.ant-form-item
  {
    margin-bottom: 18px !important;
    h3 {
      margin: 0px;
    }
  }
  .ant-form-item-has-error .ant-input{
    border-color: #ff4d4f;
  }
  div[role=alert] {
    color: #ff4d4f;
  }
}

.article-institutions {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid $color-primary-16;
  padding-bottom: 25px;
  align-items:flex-start;
  .images-article-institutions {
    img {
      border-radius: 10px;
      border: 3px solid $color-primary-1;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .img-photo{
      width: 100px;
      height: auto;
      @media only screen and (max-width: 575px) {
        width: 75px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 75px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        width: 90px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) 
      {
        width: 90px;
      }
    }
  }
  .text-article-institutions {
    padding-left: 3%;
    flex: 3;
    margin-right: 10px;  
    @media only screen and (min-width: 1200px) and (max-width: 1399px) 
    {
      padding-left: 2%;
    }
    h3 {
      color:$color-primary-2;
      font-weight: 300;
      margin-bottom: 5px;
      font-size: 18px;
      line-height: 20px;
      @media only screen and (max-width: 575px) {
        font-size: 17px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 17px;
      }
    }
    p {
      color: $color-primary-10;
      margin-bottom: 0px;
      font-size: 15px;
      line-height: 17px;
      @media only screen and (max-width: 575px) {
        font-size: 14px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .button-article-institutions {
    text-align: right;
    min-width: 120px;
    padding-top: 30px;
    .arrow_mob{
      width: 11px;
      height: auto;
    }
    @media only screen and (max-width: 575px) {
      min-width: 20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      min-width: 20px;
    }
  }
}
img.current-picture-edit {
  max-width: 220px;
  height: auto;
}

/* /Perfil */

/* Edit Profile Picture*/
/*.ant-upload-list-picture-card .ant-upload-list-item{
  width: 120px !important;
  height: 120px !important;
  margin: 0px;
}*/
.ant-upload-list-picture-card-container{
  width: 120px !important;
  height: 120px !important;
}
.ant-upload-select-picture-card span.ant-upload, div.ant-upload-select-text span.ant-upload{
  padding: 8px !important;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  @media only screen and (max-width: 575px) 
  {
      font-size: 13px;
      padding:1px 13px 1.5px 13px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) 
  {
      font-size: 13px;
      padding:1px 13px 1.5px 13px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) 
  {
      font-size: 13px;
      padding:1px 13px 1.5px 13px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) 
  {
      font-size: 13px;
      padding:1px 13px 1.5px 13px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) 
  {
      font-size: 13.5px;
      padding:1px 13px 1.5px 13px;
  }
}
.ant-upload.ant-upload-select-picture-card{
  height: auto !important;
  width: auto !important;
  min-width: 120px;
  background-color: transparent !important;
  border: none !important;
}
/* /Edit Profile Picture*/

/* People */
.bg-gray {
  padding: 35px 30px 35px 30px;
  background-color: $color-primary-13;
  position: relative;
  @media only screen and (max-width: 575px) {
    padding: 18px 15px 18px 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 18px 15px 18px 15px;
  }
  .inputSearch {
    border: none;
    width: 100%;
    box-shadow: none;
    height: 50px;
    color:$color-primary-2;
    padding:0px 10px;
    @media only screen and (max-width: 575px) {
      height: 40px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      height: 40px;
    }
    ::-webkit-input-placeholder {
      color: $color-primary-17;
    }
    ::-moz-placeholder {
      color: $color-primary-17;
    }
    :-ms-input-placeholder {
      color: $color-primary-17;
    }
    :-moz-placeholder {
      color: $color-primary-17;
    }
    &:focus,&:active{
      outline: none;
    }
  }
  .search-image
  {
    position: absolute;
    top:50%;
    transform: translateY(-12px);
    right:calc(0% + 45px);
    @media only screen and (max-width: 575px) {
      right:calc(0% + 25px);
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      right:calc(0% + 25px);
    }
  }
}

.logos-global-innovation
{
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 10px;
  @media only screen and (max-width: 575px) 
  {
    padding-bottom: 25px;
    padding-top: 10px;
    justify-content: center;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) 
  {
    padding-bottom: 25px;
    padding-top: 10px;
    justify-content: center;
  }
  h4{
    font-weight: 500;
    color:$color-primary-2;
    font-size: 20px;
    margin-bottom: 0px;
    @media only screen and (max-width: 575px) 
    {
      font-size: 17px;
      line-height: 20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
      font-size: 18px;
      line-height: 20px;
    }
  }
  img.icon-global-innovation{
    width: 40px;
    height: 40px;
    @media only screen and (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
  }
}
/* /People */

/* Settings */
/* /Settings */

/* Forms */
.formulario-ant .ant-form-item-label > label
{
  font-size: 15px;
  font-weight: 300;
  align-items: baseline;
}

.formulario-ant .ant-form-vertical .ant-form-item-label, .formulario-ant .ant-col-24.ant-form-item-label
{
  padding-bottom: 0px;
  margin-bottom: 3px;
}

.formulario-ant 
{
  .ant-row.ant-form-item
  {
    margin-bottom: 22px;
  }
  input[type="text"]
  {
    height: 40px;
    outline: none;
    box-shadow: none;
    width: 100%;
    &:focus,&:active,&:hover
    {
      border-color: $color-primary-19;
    }
  }
  .ant-picker
  {
    width: 100%;
    &:hover,&:active,&:focus{
      border-color: $color-primary-19;
    }
    .ant-picker-input > input
    {
      font-size: 14px;
      height: 40px;
    }
    &.ant-picker-focused
    {
      border-color:$color-primary-19;
      box-shadow: none;
    }
  }
  .ant-input-number
  {
    width: 100%;
    &:hover,&:active,&:focus{
      border-color: $color-primary-19;
    }
    &.ant-input-number-focused
    {
      box-shadow:none;
    }

    .ant-input-number-input
    {
      height: 40px;
    }
  }
  textarea.ant-input
  {
    min-height: 150px;
    resize: none;
    &:hover,&:active,&:focus{
      border-color: $color-primary-19;
      box-shadow: none;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
  {
    height: 40px;
    border-color: $color-primary-19;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector
  {
    border-color: $color-primary-19;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
  {
    border-color: $color-primary-19;
    box-shadow: none;
  }
  .ant-input-group-addon .ant-select
  {
    width: 100px;
    color:$color-primary-2; 
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item
  {
    padding-top: 4px;
  }
}

.ant-select-dropdown
{
  background-color: $color-primary-1 !important;
}
.ant-select-item
{
  color:$color-primary-2 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
{
  font-weight: 400 !important;
}

/* /Forms*/

/*Announcement, profile, institution, project*/
:root 
{
	--ck-image-style-spacing: 1.5em;
}
.image-style-align-center 
{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.image-style-align-left 
{
  float: left;
  margin-right: var(--ck-image-style-spacing);
}.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}.image-style-align-right 
{
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* / Announcement, profile, institution, project*/

/*InfiniteScroll loading */
.infinite-scroll-loading {
  position: fixed !important;
  left: 12%;
}
