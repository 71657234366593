

$color-primary-0:transparent; //2
$color-primary-1:#ffffff; //3
$color-primary-2:#000000; //14
$color-primary-3:#2195f2; //0
$color-primary-4:#032d56; //3
$color-primary-5:#06182d; //0
$color-primary-6:#041e42; //0
$color-primary-7:#2195f2; //0
$color-primary-8:#bcbcbc; //0
$color-primary-9:#064059; //0
$color-primary-10:#666666; //2
$color-primary-11:#000000; //0
$color-primary-12:#1D334D; //0
$color-primary-13:#e5e5e5; //1
$color-primary-14:rgba(255,255,255,0.4); //2
$color-primary-15:rgba(2,1,12,0.4); //0
$color-primary-16:#dddddd; //3
$color-primary-17:#989898; //9
$color-primary-18:#71c2ff; //2
$color-primary-19:#d9d9d9; //8







